import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import wearableIcon from '../assets/wearduke.png';
import palette from '../shared/palette';

const NotEligiblePage = () => {
  return (
    <div>
      <Container>
        <Message>
          We are sorry, but you are not eligible to participate in the WearDuke
          study at this time. If you believe you have received this message in
          error, please contact the study team at{' '}
          <a href="mailto:wearduke@duke.edu">wearduke@duke.edu</a>. Click the
          logo to go to our home page!
        </Message>
        <Link to="/">
          <Logo src={wearableIcon} alt="WearDuke Logo" />
        </Link>
      </Container>
    </div>
  );
};

export default NotEligiblePage;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0rem 2rem;
  width: 100%;
  text-align: center;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 50rem;
  height: auto;
  margin: 0 auto;
`;

const Message = styled.h1`
  font-size: 2.5rem;
  color: ${palette.dark1};
  text-align: center;
  margin: 0 auto;
  margin-bottom: 5rem;
  width: 100%;
  max-width: 100rem;
`;
