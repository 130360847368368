import React from 'react';

import Layout from '../components/layout';
import NotEligiblePage from '../NotEligiblePage/NotEligiblePage';

export default () => (
  <Layout>
    <NotEligiblePage />
  </Layout>
);
